import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
                <strong><p>{`Data: ${label}`}</p></strong>
                <p>{`Taxa de Vitória: ${payload[0].value}%`}</p>
                <p>{`Total Matches: ${payload[0].payload.totalMatches}`}</p>
            </div>
        );
    }

    return null;
}

const WinRateLineChart = ({ data }) => {
    //o date vem no formato dd/mm/yyyy mas quero mostrar somente dd/mm
    data.forEach((d) => {
        d.date = d.date.split('/').slice(0, 2).join('/');
    });
    return (
        <ResponsiveContainer width="100%" height={300}>
            <LineChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" label={{ value: 'Data', position: 'insideBottom', offset: -5 }} />
                <YAxis label={{ value: 'Taxa de Vitória (%)', angle: -90, position: 'insideLeft', dy: 70 }} />
                <Tooltip content={<CustomTooltip />} />
                <Line type="monotone" dataKey="cumulativeWinRate" stroke="#590031" activeDot={{ r: 8 }} />
            </LineChart>
        </ResponsiveContainer>
    );
}


const HSRateLineChart = ({ data }) => {
    //o date vem no formato dd/mm/yyyy mas quero mostrar somente dd/mm
    data.forEach((d) => {
        d.date = d.date.split('/').slice(0, 2).join('/');
    });
    return (
        <ResponsiveContainer width="100%" height={300}>
            <LineChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" label={{ value: 'Data', position: 'insideBottom', offset: -5 }} />
                <YAxis
                    label={{ value: 'Taxa de Headshot (%)', angle: -90, position: 'insideLeft', dy: 70 }}
                    domain={['auto', 'auto']}  // Arredonda para cima até a dezena mais próxima e adiciona 5
                    tickCount={10}  // Define o número de ticks (intervalos) no eixo Y
                />

                <Tooltip />
                <Line type="monotone" dataKey="cumulativeHSRate" stroke="#590031" activeDot={{ r: 8 }} />
            </LineChart>
        </ResponsiveContainer>
    );
}

export { WinRateLineChart, HSRateLineChart };

