import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import React from 'react';
import "primereact/resources/themes/lara-light-cyan/theme.css";

// Utils
import { decodeJWT } from './Components/jwtUtils';

//Auth
import useAuth from "./Auth/hook_useAuth";
import { setupInterceptors } from './Auth/Api';

//Layout
import Header from './Layout/Header';
import Sidebar from './Layout/Sidebar';
import Footer from './Layout/Footer';

//Paginas
import Login from './Pages/Login';
import SemPermissao from './Pages/SemPermissao';
import Dashboard from './Pages/Dashboard';
import Perfil from './Pages/Perfil';
import Configuracoes from './Pages/Configuracoes';
import Filters from './Pages/Filters';



// function MultiRoleRoute({ allowedRoles, children }) {
//     const token = JSON.parse(localStorage.getItem("user_token"));
//     const nivel = decodeJWT(token).nivel;
//     return allowedRoles.includes(nivel) ? children : <SemPermissao />;
// }


function App() {


    const { user } = useAuth()

    React.useEffect(() => {
        setupInterceptors();
    }, []);

    function AuthenticatedRoutes() {
        const token = JSON.parse(localStorage.getItem("user_token"));  // Garanta que está lendo o token corretamente
        console.log("Token recuperado:", token);
        const decodedToken = decodeJWT(token);

        var nivel = '';
        if (!decodedToken || !decodedToken.nivel) {
            console.log("Falha na decodificação ou no nível");
            localStorage.clear();
        } else {
            nivel = decodedToken.nivel;
        }

        return (
            <div className="wrapper">
                <Router>
                    <Sidebar />
                    <div className="main">
                        <Routes>
                            <Route path="*" element={nivel === 'Administrador' ? <Dashboard /> : <SemPermissao />} />
                            <Route path="/dashboard" element={nivel === 'Administrador' ? <Dashboard /> : <SemPermissao />} />
                            <Route path="/filtrar-dados" element={nivel === 'Administrador' ? <Filters /> : <SemPermissao />} />
                            <Route path="/perfil" element={nivel === 'Administrador' ? <Perfil /> : <SemPermissao />} />
                            <Route path="/configuracoes" element={nivel === 'Administrador' ? <Configuracoes /> : <SemPermissao />} />
                        </Routes>
                        <Footer />
                    </div>
                </Router>
            </div>
        )
    }


    function NotAthenticatedRoutes() {
        return (
            <Router>
                <Routes>
                    <Route path="*" element={<Login />} />
                </Routes>
            </Router>
        )
    }

    return (
        <>
            {user ? (<AuthenticatedRoutes />) : (<NotAthenticatedRoutes />)}
        </>
    );

}

export default App;
