import React from 'react';
import { RadarChart, Radar, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer, Tooltip } from 'recharts';

// Componente personalizado para o Tooltip
const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
                <strong><p className="label">{label.charAt(0).toUpperCase() + label.slice(1)}</p></strong>
                <p>{`Win Rate: ${(payload[0].value * 100).toFixed(2)}%`}</p>
                <p>{`Total wins: ${payload[0].payload.totalWins}`}</p>
                <p>{`Total matches: ${payload[0].payload.totalMatches}`}</p>
            </div>
        );
    }

    return null;
};

// Componente do gráfico RadarChart
const RadarChartComponent = ({ data }) => {
    return (
        <ResponsiveContainer width="100%" height="100%" minHeight={400}>
            <RadarChart outerRadius="80%" data={data}>
                <PolarGrid />
                <PolarAngleAxis dataKey="agente" />
                <PolarRadiusAxis angle={30} domain={[0, 1]} />
                <Tooltip content={<CustomTooltip />} />
                <Radar name="Win rate" dataKey="winrate" stroke="#3b0167" fill="#590031" fillOpacity={0.6} />
            </RadarChart>
        </ResponsiveContainer>
    );
}

export default RadarChartComponent;
