import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import Api from '../Auth/Api';

function Configuracoes() {

    // Token
    const token = JSON.parse(localStorage.getItem("user_token"))

    // Utilizando o hook useForm do react-hook-form para controle do formulário
    const { register, handleSubmit, formState: { errors } } = useForm();

    // Função para lidar com o submit do formulário
    const onSubmit = async (data) => {
        try {
            const response = await Api.post(`/api/editar-ajustes`, data, { headers: { Authorization: `Bearer ${token}` } });
            // Mensagem de sucesso
            toast.success(response.data.message, { position: "top-right", autoClose: 4000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true });

        } catch (error) {
            // Mensagem de erro
            toast.error(error.response.data.message, { position: "top-right", autoClose: 4000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true });
        }
    };

    const [dados, setDados] = React.useState([]);
    React.useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/ajustes`, { headers: { Authorization: `Bearer ${token}` } });
                setDados(response.data.message);
            } catch (error) {
                console.error(error.response.data.message)
            }
        }
        fetchData();
    }, [token]);

    return (
        <>
            <ToastContainer />
            <main className="content">
                <Container fluid className="p-0">
                    <Row className="justify-content-center">
                        <Col md={6}>
                            <h1 className="h3 mb-3 text-center">Configurações</h1>
                            <div className="card">
                                <div className="card-body">
                                    <Form onSubmit={handleSubmit(onSubmit)}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Tempo em <b>horas</b> do intervalo das tentativas do bloqueio.</Form.Label>
                                            <Form.Control
                                                type="number"
                                                defaultValue={dados.h_desbloqueio}
                                                {...register("h_desbloqueio", { required: true, min: 1 })}
                                                isInvalid={!!errors.h_desbloqueio}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.h_desbloqueio?.type === 'required' && "Este campo é obrigatório."}
                                                {errors.h_desbloqueio?.type === 'min' && "O valor deve ser maior que 0."}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <div className="text-center">
                                            <Button variant="primary" type="submit">Salvar</Button>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </main>
        </>
    );
}

export default Configuracoes;
