import { createContext, useEffect, useState } from "react"

export const AuthContext = createContext({})
export const AuthProvider = ({ children }) => {

    const [user, setUser] = useState();

    useEffect(() => {
        const userToken = localStorage.getItem("user_token")
        if (userToken) {
            setUser(true)
            return
        }
    }, []);

    const signin = (data, result) => {

        if (result) {
            localStorage.setItem("user_token", JSON.stringify(data.token))
            setUser(true)
            return
        }

    };

    const signout = () => {
        setUser(null)
        localStorage.clear()
        return
    };

    return (
        <AuthContext.Provider value={{ user, signin, signout }}>
            {children}
        </AuthContext.Provider>
    );
};