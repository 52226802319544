import axios from 'axios';

const Api = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

export function setupInterceptors() {
    Api.interceptors.response.use(
        response => {
            return response;
        },
        error => {
            if (error.response && ((error.response.data === "Expired token") || (error.response.data === "Signature verification failed"))) {
                // Aqui você pode limpar qualquer informação de autenticação armazenada localmente
                localStorage.removeItem("user_token");
                localStorage.removeItem("nome_completo");
    
                // Redireciona o usuário para a página de login usando o método tradicional
                window.location.href = "/login";
            }
            return Promise.reject(error);
        }
    );
    
}

export default Api;
