import React from 'react';
import { useForm } from 'react-hook-form';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import Api from '../Auth/Api';
import { decodeJWT } from '../Components/jwtUtils';

function Perfil() {

    const token = JSON.parse(localStorage.getItem("user_token"))
    const idUsuario = decodeJWT(token).id;

    const [usuario, setUsuario] = React.useState([]);
    React.useEffect(() => {
        async function fetchData() {
            try {
                console.log(idUsuario)
                const response = await Api.get(`api/usuario/${idUsuario}`, { headers: { Authorization: `Bearer ${token}` } });
                console.log(response.data)
                setUsuario(response.data.usuario);
            } catch (error) {
                console.error(error.response.data.message)
            }
        }
        fetchData();
    }, [token, idUsuario]);


    // Hook useForm
    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        defaultValues: usuario
    });

    // Função para lidar com o submit do formulário
    const onSubmit = async (data) => {
        console.log(data)
        if(data.nova_senha !== data.nova_senha_confirma){
            toast.error('As senhas não conferem.', { position: "top-right", autoClose: 4000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true });
            return;
        }
        // Remover chaves do objeto data
        delete data.senha;
        delete data.codigo_recuperacao;
        delete data.nivel;

        try {
            const response = await Api.post(`/api/editar-usuario/${data.id}`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            // Mensagem de sucesso
            toast.success(response.data.message, { position: "top-right", autoClose: 4000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true });
        } catch (error) {
            // Mensagem de erro
            toast.error(error.response.data.message, { position: "top-right", autoClose: 4000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true });
        }
    };

    // Configurar os valores do formulário com os dados do usuário (opcional, se necessário)
    React.useEffect(() => {
        Object.keys(usuario).forEach(key => {
            setValue(key, usuario[key]);
        });
    }, [setValue, usuario]);

    return (
        <main className="content">
            <Container fluid className="p-0">
                <h1 className="h3 mb-3 text-center">Meu Perfil</h1>
                <Row className="justify-content-center">
                    <Col xl={8} md={10}>
                        <div className="card flex-fill">
                            <div className="card-body">
                                <Form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Nome Completo</Form.Label>
                                        <Form.Control type="text" {...register("nome", { required: true })} isInvalid={!!errors.nome_completo} />
                                        <Form.Control.Feedback type="invalid">Nome é obrigatório.</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-5">
                                        <Form.Label>E-mail</Form.Label>
                                        <Form.Control type="email" {...register("email", { required: true })} isInvalid={!!errors.email} />
                                        <Form.Control.Feedback type="invalid">Email é obrigatório.</Form.Control.Feedback>
                                    </Form.Group>
                                    <h4>Alterar Senha</h4>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Senha Atual</Form.Label>
                                        <Form.Control type="password" {...register("senha_atual")} />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Nova Senha</Form.Label>
                                        <Form.Control type="password" {...register("nova_senha")} />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Confirmar Nova Senha</Form.Label>
                                        <Form.Control type="password" {...register("nova_senha_confirma")} />
                                    </Form.Group>
                                    <div className="text-center">
                                        <Button variant="primary" type="submit">Salvar Alterações</Button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <ToastContainer />
        </main>
    );
}

export default Perfil;
