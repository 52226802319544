import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Badge } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';
import Api from '../Auth/Api';
import { decodeJWT } from '../Components/jwtUtils';
import Table from '../Components/Table';
import KdMapBarChart from '../Components/Charts/BarChart_data';
import { WinRateLineChart, HSRateLineChart } from '../Components/Charts/LineChart_data';
import HourlyWinsLossesChart from '../Components/Charts/HourlyChart';

function Filters() {
    const token = JSON.parse(localStorage.getItem("user_token"))
    const idUsuario = decodeJWT(token).id;

    const [selectedRoles, setSelectedRoles] = useState([]);
    const [selectedAgents, setSelectedAgents] = useState([]);
    const [selectedTeammates, setSelectedTeammates] = useState([]);
    const [selectedMaps, setSelectedMaps] = useState([]);
    const [rolesOptions, setRolesOptions] = useState([]);
    const [teammatesOptions, setTeammatesOptions] = useState([]);
    const [agentsOptions, setAgentsOptions] = useState([]);
    const [mapsOptions, setMapsOptions] = useState([]);



    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/agentes/`,
                    {
                        headers:
                            { Authorization: `Bearer ${token}` }
                    });


                setAgentsOptions(response.data.map(agent => ({ value: agent.id, label: agent.nome.toUpperCase(), role: agent.funcao })));

                const roles = [...new Set(response.data.map(agent => agent.funcao))];
                const rolesOptions = roles.map(role => ({ value: role, label: role.toUpperCase() }));
                setRolesOptions(rolesOptions);

                const teammatesResponse = await Api.get(`api/teammates/`,
                    {
                        headers:
                            { Authorization: `Bearer ${token}` }
                    });

                setTeammatesOptions(teammatesResponse.data.map(teammate => ({ value: teammate.id, label: teammate.nome.toUpperCase(), puuid: teammate.puuid })));

                const mapsResponse = await Api.get(`api/maps/`,
                    {
                        headers:
                            { Authorization: `Bearer ${token}` }
                    });

                setMapsOptions(mapsResponse.data.map(map => ({ value: map.id, label: map.nome.toUpperCase() })));

                submit();

            } catch (error) {
                console.error(error.response.data.message)

                if (error.response.data.message === "Invalid token") {
                    localStorage.removeItem("user_token");
                    window.location.href = "/login";
                }
            }
        }
        if (idUsuario && token) {
            fetchData();
        }
    }, []);



    const [data, setData] = useState([]);
    const submit = () => {
        console.log(selectedMaps);
        async function fetchData() {
            try {
                const response = await Api.post(`api/matchData/`, {
                    roles: selectedRoles,
                    agents: selectedAgents,
                    teammates: selectedTeammates,
                    maps: selectedMaps
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                console.log(response.data.data);
                setData(response.data.data);

            }
            catch (error) {
                console.error(error.response.data.message)

                if (error.response.data.message === "Invalid token") {
                    localStorage.removeItem("user_token");
                    window.location.href = "/login";
                }
            }
        }
        fetchData();
    };

    const handleRoleChange = (selectedOptions) => {
        setSelectedRoles(selectedOptions);
    };

    const handleAgentChange = (selectedOptions) => {
        setSelectedAgents(selectedOptions);
    };

    const handleTeammateChange = (selectedOptions) => {
        setSelectedTeammates(selectedOptions);
    };
    const handleMapChange = (selectedOptions) => {
        setSelectedMaps(selectedOptions);
    };

    const columns = [
        {
            Header: 'ID',
            accessor: 'id',
            disableSortBy: true,
        },
        {
            Header: 'Kills',
            accessor: 'kills',
        },
        {
            Header: 'Deaths',
            accessor: 'deaths',
        },
        {
            Header: 'Assists',
            accessor: 'assists',
        },
        {
            Header: 'HS',//headshots/(bodyshots +legshots)
            accessor: row => ((Number(row.headshots) / (Number(row.bodyshots) + Number(row.legshots) + Number(row.headshots)) * 100)).toFixed(2) + '%',
        },
        {
            Header: 'KD',
            accessor: row => ((Number(row.kills)) / Number(row.deaths)).toFixed(2),
        },
        {
            Header: 'KDA',
            accessor: row => ((Number(row.kills) + Number(row.assists)) / Number(row.deaths)).toFixed(2),
        },
        {
            Header: 'Map',
            accessor: 'map',
        },
        {
            Header: 'Agent',
            accessor: 'agent',
        },
        {
            Header: 'Result',
            disableSortBy: true,
            accessor: row => {
                if (Number(row.rounds_won) > Number(row.rounds_lost)) {
                    return <Badge bg="success">{row.rounds_won + ':' + row.rounds_lost}</Badge>;
                } else if (Number(row.rounds_won) < Number(row.rounds_lost)) {
                    return <Badge bg="danger">{row.rounds_won + ':' + row.rounds_lost}</Badge>;
                } else {
                    return <Badge bg="secondary">{row.rounds_won + ':' + row.rounds_lost}</Badge>;
                }
            },
        },
        {
            Header: 'Date',
            accessor: 'startTime',
            Cell: ({ value }) => (formatDate(Number(value))).replace(',', ''),
        },
    ];

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleDateString('pt-BR', { hour: 'numeric', minute: 'numeric' });
    }

    const [info, setInfo] = useState({}); //informações gerais sobre as partidas
    const [kdMapData, setKdMapData] = useState([]); //grafico de barras que relaciona KD e mapa
    const [kdAgentData, setKdAgentData] = useState([]); //grafico de barras que relaciona KD e agente
    const [mapWinData, setMapWinData] = useState([]); //grafico de barras que relaciona win rate e mapa
    const [winRateData, setWinRateData] = useState([]); //grafico de linha que relaciona win rate e tempo
    const [hourlyDistribution, setHourlyDistribution] = useState([]); //grafico de barras que relaciona partidas e hora

    useEffect(() => {
        //preparar dados para todos os gráficos

        //gráfico de barra que vai relacionar KD e mapa
        const kdMapData = data.reduce((acc, row) => {
            const map = row.map;
            if (!acc[map]) {
                acc[map] = { kills: 0, deaths: 0 };
            }
            acc[map].kills += Number(row.kills);
            acc[map].deaths += Number(row.deaths);
            return acc;
        }, {});
        const kdMapDataArray = Object.entries(kdMapData).map(([map, { kills, deaths }]) => ({
            map,
            kd: kills / deaths,
            kills,
            deaths
        }));
        setKdMapData(kdMapDataArray);

        //grafico de barra que vai relacionar KD e agente
        const kdAgentData = data.reduce((acc, row) => {
            const agent = row.agent;
            if (!acc[agent]) {
                acc[agent] = { kills: 0, deaths: 0 };
            }
            acc[agent].kills += Number(row.kills);
            acc[agent].deaths += Number(row.deaths);
            return acc;
        }, {});
        const kdAgentDataArray = Object.entries(kdAgentData).map(([agent, { kills, deaths }]) => ({
            agent,
            kd: kills / deaths,
            kills,
            deaths
        }));
        setKdAgentData(kdAgentDataArray);

        //grafico de win por mapa
        const mapWinData = data.reduce((acc, row) => {
            const map = row.map;
            if (!acc[map]) {
                acc[map] = { wins: 0, matches: 0 };
            }
            if (row.win == 1) {
                acc[map].wins += 1;
            }
            acc[map].matches += 1;
            return acc;
        }, {});
        const mapWinDataArray = Object.entries(mapWinData).map(([map, { wins, matches }]) => ({
            map,
            winRate: wins / matches,
            wins,
            matches,
        }));
        setMapWinData(mapWinDataArray);

        // Grafico de vitoria ao longo do tempo
        const groupByDate = data.reduce((acc, match) => {
            // Obter a data formatada, que inclui a hora
            let formattedDate = formatDate(Number(match.startTime));
            // Se a data incluir a hora, vamos separar e manter apenas a parte da data
            formattedDate = formattedDate.split(',')[0];  // Isso presume que a data e a hora são separadas por uma vírgula

            // Verificar se já existe um acumulador para essa data
            if (!acc[formattedDate]) {
                acc[formattedDate] = {
                    date: formattedDate,
                    wins: 0,
                    totalMatches: 0,
                    totalHeadshots: 0,
                    totalShots: 0
                };
            }

            // Contabilizar partidas, vitórias e tiros
            acc[formattedDate].totalMatches += 1;
            acc[formattedDate].totalHeadshots += Number(match.headshots);
            acc[formattedDate].totalShots += Number(match.headshots) + Number(match.bodyshots) + Number(match.legshots);
            if (match.win === "1") {
                acc[formattedDate].wins += 1;
            }

            return acc;
        }, {});

        // Ordenar por data
        const sortedDates = Object.keys(groupByDate).sort((a, b) => new Date(a.split('/').reverse().join('/')) - new Date(b.split('/').reverse().join('/')));

        // Converter o objeto acumulado em um array e calcular taxa de vitória e HS acumulada
        let cumulativeWins = 0;
        let cumulativeMatches = 0;
        let cumulativeHeadshots = 0;
        let cumulativeShots = 0;
        const winRateData = sortedDates.map(date => {
            const { wins, totalMatches, totalHeadshots, totalShots } = groupByDate[date];
            cumulativeWins += wins;
            cumulativeMatches += totalMatches;
            cumulativeHeadshots += totalHeadshots;
            cumulativeShots += totalShots;
            return {
                date: date,
                cumulativeWinRate: (cumulativeWins / cumulativeMatches * 100).toFixed(2),
                cumulativeHSRate: (cumulativeHeadshots / cumulativeShots * 100).toFixed(2),
                totalMatches: cumulativeMatches
            };
        });

        setWinRateData(winRateData); // Se você precisar do array para o gráfico




        //distribuicao por hora
        const groupByHour = data.reduce((acc, match) => {
            // Extrair a hora do timestamp da partida
            const hour = getHour(Number(match.startTime));

            // Inicializar ou atualizar contadores para essa hora
            if (!acc[hour]) {
                acc[hour] = {
                    hour,
                    wins: 0,
                    losses: 0,
                    totalMatches: 0
                };
            }

            // Contabilizar partidas
            acc[hour].totalMatches += 1;
            if (match.win === "1") {
                acc[hour].wins += 1;
            } else {
                acc[hour].losses += 1;
            }

            return acc;
        }, {});

        // Converter o objeto acumulado em um array
        const hourlyDistribution = Object.values(groupByHour).sort((a, b) => a.hour - b.hour);
        setHourlyDistribution(hourlyDistribution);


        //setInfo informações de HS geral, win rate geral, total de partidas vencidas, total de partidas
        const totalMatches = data.length;
        const totalWins = data.filter(match => match.win == "1").length;
        const totalHeadshots = data.reduce((acc, match) => acc + Number(match.headshots), 0);
        const totalShots = data.reduce((acc, match) => acc + Number(match.headshots) + Number(match.bodyshots) + Number(match.legshots), 0);
        const headShotRate = (totalHeadshots / totalShots * 100).toFixed(2);
        const winRate = (totalWins / totalMatches * 100).toFixed(2);
        setInfo({
            totalMatches,
            totalWins,
            totalHeadshots,
            totalShots,
            headShotRate,
            winRate
        });





    }, [data]);

    const getHour = (timestamp) => {
        const date = new Date(parseInt(timestamp));
        return date.getHours();  // Retorna a hora do dia de 0 a 23
    };

    const charts = [
        kdMapData.length > 1 ? kdMapData : null,
        mapWinData.length > 1 ? mapWinData : null,
        kdAgentData.length > 1 ? kdAgentData : null
    ].filter(Boolean);

    // Calcula o tamanho da coluna baseado na quantidade de gráficos ativos
    const colSize = 12 / charts.length;

    return (
        <main className="content">
            <div className="container-fluid p-0">
                <Row className="align-items-center justify-content-between">
                    <Col>
                        <h1 className="h3 mb-3">Filter data</h1>
                    </Col>
                    <Col xs="auto">
                        <Button onClick={submit} variant="primary">Apply Filters</Button>
                    </Col>
                </Row>


                <div className="row">
                    <Container fluid className="p-0">
                        <Row>
                            <Col md={3}>
                                <label>Role</label>
                                <Select
                                    isMulti
                                    options={rolesOptions}
                                    onChange={handleRoleChange}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                />
                            </Col>
                            <Col md={3}>
                                <label>Agent</label>
                                <Select
                                    isMulti
                                    options={agentsOptions}
                                    onChange={handleAgentChange}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                />
                            </Col>
                            <Col md={3}>
                                <label>Teammates</label>
                                <Select
                                    isMulti
                                    options={teammatesOptions}
                                    onChange={handleTeammateChange}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                />
                            </Col>
                            <Col md={3}>
                                <label>Map</label>
                                <Select
                                    isMulti
                                    options={mapsOptions}
                                    onChange={handleMapChange}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col md={4}>
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">Headshot Rate</h5>
                                        <h6 className="card-subtitle mb-2 text-muted">Sua taxa média de HS entre as partidas é <strong style={{ fontSize: 16 }}>{info.headShotRate}%</strong> Foram {info.totalHeadshots} tiros certeiros.</h6>
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">Win Rate</h5>
                                        <h6 className="card-subtitle mb-2 text-muted">
                                            Você venceu {info.totalWins} {info.totalWins === 1 ? 'partida' : 'partidas'}, isso corresponde a <strong style={{ fontSize: 16 }}>{info.winRate}%</strong> de win rate.
                                        </h6>
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">Total Matches</h5>
                                        <h6 className="card-subtitle mb-2 text-muted">
                                            {info.totalMatches === 1 ? 'Foi encontrada apenas ' : 'Foram encontradas '}{info.totalMatches} {info.totalMatches === 1 ? 'partida' : 'partidas'} com parâmetros especificados.
                                        </h6>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            {charts.includes(kdMapData) && (
                                <Col md={colSize}>
                                    <div className="card">
                                        <div className="card-body">
                                            <h5 className="card-title">KD por mapa</h5>
                                            <KdMapBarChart data={kdMapData} />
                                        </div>
                                    </div>
                                </Col>
                            )}
                            {charts.includes(mapWinData) && (
                                <Col md={colSize}>
                                    <div className="card">
                                        <div className="card-body">
                                            <h5 className="card-title">Win por mapa</h5>
                                            <KdMapBarChart data={mapWinData} />
                                        </div>
                                    </div>
                                </Col>
                            )}
                            {charts.includes(kdAgentData) && (
                                <Col md={colSize}>
                                    <div className="card">
                                        <div className="card-body">
                                            <h5 className="card-title">KD por agente</h5>
                                            <KdMapBarChart data={kdAgentData} />
                                        </div>
                                    </div>
                                </Col>
                            )}
                        </Row>
                        <Row>
                            {winRateData.length > 1 &&
                                <Col md={6}>
                                    <div className="card">
                                        <div className="card-body">
                                            <h5 className="card-title">Variação de vitória pelo tempo</h5>
                                            <WinRateLineChart data={winRateData} />
                                        </div>
                                    </div>
                                </Col>
                            }
                            {winRateData.length > 1 &&
                                <Col md={6}>
                                    <div className="card">
                                        <div className="card-body">
                                            <h5 className="card-title">Variação de HS pelo tempo</h5>
                                            <HSRateLineChart data={winRateData} />
                                        </div>
                                    </div>
                                </Col>
                            }

                            {hourlyDistribution.length > 1 && (
                                <Col md={12}>
                                    <div className='card'>
                                        <div className='card-body'>
                                            <h5 className='card-title'>Distribuição de W/L por hora</h5>
                                            <HourlyWinsLossesChart data={hourlyDistribution} />
                                        </div>
                                    </div>
                                </Col>
                            )}

                        </Row>
                        <Row>
                            <Col md={12} className='card mt-4'>
                                <Table columns={columns} data={data} />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </main>
    );
}

export default Filters;
