import { useNavigate } from 'react-router-dom';
import useAuth from '../Auth/hook_useAuth';
import Api from '../Auth/Api';
import { decodeJWT } from '../Components/jwtUtils';
import Dropdown from 'react-bootstrap/Dropdown';
import { FiArrowDown, FiLogOut, FiUser } from 'react-icons/fi';
import { PiUserFocusThin } from "react-icons/pi";

function Header() {

    const token = JSON.parse(localStorage.getItem("user_token"))
    const navigate = useNavigate();
    const { signout } = useAuth();
    const nome_completo = localStorage.getItem("nome_completo")
    const foto = localStorage.getItem("foto")


    return (
        <nav className="navbar navbar-expand navbar-light navbar-bg">

            <div className="navbar-collapse collapse">
                <ul className="navbar-nav navbar-align">


                    <Dropdown as="li" className="nav-item">

                        <Dropdown.Toggle as="a" className="nav-link">
                            {/* <img src="./assets/img/user.png" className="avatar img-fluid rounded me-1 hide-on-mobile" alt="perfil" /> */}
                            <PiUserFocusThin className="avatar rounded me-1 hide-on-mobile" />
                            <span className='header-title'>Administrador <FiArrowDown className="align-middle ms-1" /></span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="dropdown-menu-end">
                            <Dropdown.Item onClick={() => navigate('/perfil')}>
                                <FiUser className="align-middle me-1" /> Meu Perfil
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item onClick={signout}><FiLogOut className="align-middle me-1" /> Sair</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </ul>
            </div>
        </nav>
    )

}

export default Header;
