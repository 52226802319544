import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container-fluid">
                <div className="row text-muted">
                    <div className="col-6 text-start">
                        <p className="mb-0">
                            <Link className="text-muted" target="_blank"><strong>Tricksters Clan</strong></Link> &copy;
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
