import React from 'react';
import { useTable, usePagination, useGlobalFilter, useFilters, useSortBy } from 'react-table';
import { OverlayPanel } from 'primereact/overlaypanel';

import { Link } from 'react-router-dom';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import { Dropdown } from 'primereact/dropdown';
import { FiFilter } from "react-icons/fi";

// Filtro
function DefaultColumnFilter({ column: { filterValue, setFilter, id, preFilteredRows, Header } }) {
    const op = React.useRef(null);

    // Coletando valores únicos
    const options = React.useMemo(() => {
        const optionsSet = new Set();
        preFilteredRows.forEach(row => {
            optionsSet.add(row.values[id]);
        });
        return Array.from(optionsSet).map(value => ({ label: value, value }));
    }, [id, preFilteredRows]);

    const handleFilterChange = (e) => {
        setFilter(e.value || undefined);
    };

    // Assegurando que Header seja uma string para o placeholder
    const headerText = typeof Header === 'string' ? Header : "Filtrar";

    return (
        <>
            <span className='cursor-pointer' onClick={(e) => op.current.toggle(e)} aria-controls="overlay_panel" aria-haspopup><FiFilter /></span>
            <OverlayPanel ref={op} id="overlay_panel" dismissable>
                <div style={{ minWidth: "200px", marginBottom: '0.5em' }}>
                    <Dropdown
                        id={`filter-${id}`}
                        value={filterValue}
                        options={options}
                        onChange={handleFilterChange}
                        optionLabel="label"
                        placeholder={`${headerText}...`}
                        showClear
                        filter
                        filterBy="label"
                        autoFocus
                    />
                </div>
            </OverlayPanel>
        </>
    );
}


function Table({ columns, data, length = true, search = true, info = true }) {
    // Inicialize useTable com useFilters
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        nextPage,
        previousPage,
        setPageSize,
        canNextPage,
        canPreviousPage,
        state: { pageSize, pageIndex },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageSize: 20 },
            // Remova ou comente a linha abaixo para desabilitar o filtro padrão
            // defaultColumn: { Filter: DefaultColumnFilter }, 
        },
        useSortBy,      // Apenas useSortBy
        usePagination,
    );



    return (
        <>
            {/* Filtro global removido para focar nos filtros de coluna */}
            <table {...getTableProps()} className="table table-striped table-sm mb-2 mt-3">
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? ' ▴'
                                                : ' ▾'
                                            : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => (
                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            <div className="d-flex justify-content-center align-items-center">
                <nav>
                    <ul className="pagination">
                        <li className="page-item">
                            <Link onClick={() => previousPage()} className="page-link" to="#"><FiArrowLeft /></Link>
                        </li>
                        <li className="page-item">
                            <Link onClick={() => nextPage()} className="page-link" to="#"><FiArrowRight /></Link>
                        </li>
                    </ul>
                </nav>
                <Dropdown
                    value={pageSize}
                    options={[
                        { label: '20', value: 20 },
                        { label: '50', value: 50 },
                        { label: '100', value: 100 },
                        { label: '500', value: 500 },
                        { label: '1000', value: 1000 },
                    ]}
                    onChange={e => setPageSize(e.value)}
                    placeholder="Quantidade"
                />
            </div>
        </>
    );
}

export default Table;
