import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ReferenceLine, ResponsiveContainer } from 'recharts';

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
                <strong><p>{`${label}`}</p></strong>
                {payload[0].payload.kills && <p>{`Kills: ${payload[0].payload.kills}`}</p>}
                {payload[0].payload.deaths && <p>{`Deaths: ${payload[0].payload.deaths}`}</p>}
                {payload[0].payload.kd && <p>{`KD: ${payload[0].payload.kd.toFixed(3)}`}</p>}

                {payload[0].payload.winRate && <p>{`Win rate: ${payload[0].payload.winRate.toFixed(2)}`}</p>}
                {payload[0].payload.wins && <p>{`Wins: ${payload[0].payload.wins}`}</p>}
                {payload[0].payload.matches && <p>{`Matches: ${payload[0].payload.matches}`}</p>}

            </div>
        );
    }

    return null;
};

const KdMapBarChart = ({ data }) => {
    const xDataKey = Object.keys(data[0])[0];
    const dataKey = Object.keys(data[0])[1];
    const win_or_kd = dataKey === 'winRate' ? 0 : 1;
    return (
        <ResponsiveContainer width="100%" height={400}>
            <BarChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={xDataKey} tick={{ fontSize: 12 }} angle={-22} textAnchor="end" interval={0} />
                <YAxis />
                <Tooltip content={<CustomTooltip />} />
                <Bar type='monotone' dataKey={dataKey} stroke="#3b0167" fill="#590031" fillOpacity={0.8} name="KD Ratio" />
                <ReferenceLine y={win_or_kd ? 1 : 0.5} stroke="#590031" strokeDasharray="3 3" label={{ position: 'top', fill: 'red', fontSize: 12 }} />
            </BarChart>
        </ResponsiveContainer>
    );
}

export default KdMapBarChart;
