import { AreaChart, Area, ResponsiveContainer, XAxis, YAxis, Tooltip, BarChart, Bar } from 'recharts';

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
                <strong><p className="label">{`${label.charAt(0).toUpperCase() + label.slice(1)}`}</p></strong>
                <p className="intro">{`Win Rate: ${(payload[0].value * 100).toFixed(2)}%`}</p>
                <p className="desc">{`Total Wins: ${payload[0].payload.totalWins}`}</p>
                <p className="desc">{`Total Matches: ${payload[0].payload.totalMatches}`}</p>
            </div>
        );
    }

    return null;
};

const BarChartComponent = ({ data }) => {
    return (
        <ResponsiveContainer width="100%" height={400}>
            <BarChart data={data}>
                <XAxis dataKey="role" label={{ value: 'Role', position: 'insideBottom', offset: -5 }} />
                <YAxis label={{ value: 'Win Rate', angle: -90, position: 'insideLeft', offset: 10 }} />
                <Tooltip content={<CustomTooltip />} />
                <Bar type="monotone" dataKey="winRate" stroke="#3b0167" fill="#590031" fillOpacity={0.6} />
            </BarChart>
        </ResponsiveContainer>
    );
}
export default BarChartComponent;
