import { FiTarget } from "react-icons/fi";
import { FiStar, FiClipboard, FiCrosshair } from "react-icons/fi";
import RadarChartComponent from "../Components/Charts/RadarChartComponent";
import BarChartComponent from "../Components/Charts/BarChartComponent";
import { useEffect, useState } from "react";
import Api from "../Auth/Api";
import { decodeJWT } from "../Components/jwtUtils";


function Dashboard() {
    const token = JSON.parse(localStorage.getItem("user_token"))
    const idUsuario = decodeJWT(token).id;

    const [data, setData] = useState([]);
    const [bestAgent, setBestAgent] = useState(null);
    const [agentsList, setAgentsList] = useState([]);


    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/dashboard/${idUsuario}`,
                    {
                        headers:
                            { Authorization: `Bearer ${token}` }
                    });
                setData(response.data);

                setBestAgent(findBestAgent(response.data.playerAgents));
                //trocando o objeto de agentes para um array de objetos
                

                setAgentsList(response.data.agentsList);

            } catch (error) {
                console.error(error.response.data.message)
                //se for erro de token inválido, redireciona para a página de login
                if (error.response.data.message === "Invalid token") {
                    localStorage.removeItem("user_token");
                    window.location.href = "/login";
                }
            }
        }
        if (idUsuario && token) {
            fetchData();
        }

    }, [idUsuario, token]);

    const findBestAgent = (agentsData) => {
        let bestAgent = null;
        let bestScore = 0;

        // Itera sobre cada agente no objeto
        for (const agent in agentsData) {
            const data = agentsData[agent];
            // Calcula o score como um produto da taxa de vitória e a raiz quadrada do número de partidas
            // A raiz quadrada ajuda a balancear o impacto do número total de partidas
            const score = parseFloat(data.winRate) * Math.sqrt(data.totalMatches);

            // Atualiza o melhor agente se este agente tem um score melhor
            if (score > bestScore) {
                bestAgent = {
                    name: agent,
                    totalMatches: data.totalMatches,
                    totalWins: data.totalWins,
                    winRate: data.winRate,
                    score: score.toFixed(2) // arredonda para duas casas decimais para melhor visualização
                };
                bestScore = score;
            }
        }

        return bestAgent;
    }

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleDateString('pt-BR');
    }

    return (
        <>
            <main className="content">
                <div className="container-fluid p-0">
                    <h1 className="h3 mb-3">Dashboard</h1>
                    {data.headShotRate && (
                        <div className="w-100">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="card card-dashboard">
                                        <div className="card-body card-body-dashboard d-flex align-items-center justify-content-between">
                                            <div className="icon-container d-flex align-items-center">
                                                <FiClipboard />
                                            </div>
                                            <div className="text-end">
                                                <span className="card-text card-text-dashboard">{data.winRate.winRate}</span>
                                                <h5 className="card-title card-title-dashboard">Win Rate</h5>
                                                <p className="card-text card-text-dashboard">Total Matches: {data.winRate.totalMatches}</p>
                                                <p className="card-text card-text-dashboard">Last match: {formatDate(Number(data.winRate.firstMatch))}</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card card-dashboard">
                                        <div className="card-body card-body-dashboard d-flex align-items-center justify-content-between">
                                            <div className="icon-container d-flex align-items-center">
                                                <FiTarget />
                                            </div>
                                            <div className="text-end">
                                                <span className="card-text card-text-dashboard">{data.headShotRate.headShotRate}</span>
                                                <h5 className="card-title card-title-dashboard">Headshot Rate</h5>
                                                <p className="card-text card-text-dashboard">Total Shots: {data.headShotRate.totalShots}</p>
                                                <p className="card-text card-text-dashboard">Total Headshots: {data.headShotRate.totalHeadshots}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card card-dashboard">
                                        <div className="card-body card-body-dashboard d-flex align-items-center justify-content-between">
                                            <div className="icon-container d-flex align-items-center">
                                                <FiStar />
                                            </div>
                                            <div className="text-end">
                                                <span className="card-text card-text-dashboard">{bestAgent && bestAgent.name.charAt(0).toUpperCase() + bestAgent.name.slice(1)}</span>
                                                <h5 className="card-title card-title-dashboard">Best Agent</h5>
                                                <p className="card-text card-text-dashboard">Total Matches: {bestAgent && bestAgent.totalMatches}</p>
                                                <p className="card-text card-text-dashboard">Win rate: {bestAgent && bestAgent.winRate}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="card card-dashboard">
                                        <div className="card-body card-body-dashboard d-flex align-items-center justify-content-between">
                                            {/* <span className="card-text card-text-dashboard" style={{ position: "absolute", top: 25, left: 25 }}>Agents Win Rate</span> */}
                                            {agentsList && agentsList.length > 0 && (
                                                <RadarChartComponent data={agentsList} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="card card-dashboard">
                                        <div className="card-body card-body-dashboard d-flex align-items-center justify-content-between">
                                            {agentsList && agentsList.length > 0 && (
                                                <BarChartComponent data={data.rolesWR} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </main>
        </>
    )
}

export default Dashboard;