export function decodeJWT(token) {
    try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
    } catch (error) {
        console.error("Erro ao decodificar o token:", error);
        return null;
    }
}

export function formatDate(date) {
    const [year, month, day] = date.split("-");

    return `${day}/${month}/${year}`;
}

export function formatDateTime(dateTimeString) {
    const date = new Date(dateTimeString);

    let day = date.getDate();
    day = day < 10 ? `0${day}` : day;

    const year = date.getFullYear();

    const hours = date.getHours();

    let month = date.getMonth() + 1;
    month = month < 10 ? `0${month}` : month;

    let minutes = date.getMinutes();
    minutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${day}/${month}/${year} ${hours}:${minutes}`;

}

export function formatDateSimple(inputDate) {
    // Verifica se a data está no formato esperado
    const datePattern = /^\d{4}-\d{2}-\d{2}$/;
    if (!datePattern.test(inputDate)) {
        throw new Error('Data deve estar no formato YYYY-MM-DD');
    }
    
    // Extrai o ano e o mês da data
    const year = inputDate.substring(0, 4);
    const month = inputDate.substring(5, 7);
    
    // Retorna a data no novo formato
    return `${month}/${year.substring(2)}`;
}

export function processDataHora(dataHora) {
    // Cria um objeto Date a partir da string fornecida
    const data = new Date(dataHora);
  
    // Formata a data como DD/MM/YYYY
    const dataFormatada = data.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  
    // Formata a hora como HH:MM
    const horaFormatada = data.toLocaleTimeString('pt-BR', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false // Use true para formato 12 horas (AM/PM)
    });
  
    // Retorna ambos os valores
    return { dataFormatada, horaFormatada };
  }

export function truncateString(str, limit = 80) {
    if (str.length > limit) {
        return str.substring(0, limit) + '...';
    } else {
        return str;
    }
}

export function obterIniciais(nomeCompleto) {
    // Divide o nome completo em palavras
    const palavras = nomeCompleto.split(' ');

    // Inicializa uma variável para armazenar as iniciais
    let iniciais = '';

    // Itera pelas palavras e obtém a primeira letra de cada palavra
    for (let i = 0; i < palavras.length; i++) {
        const palavra = palavras[i];
        if (palavra.length > 0) {
            iniciais += palavra[0].toUpperCase(); // Converte a primeira letra para maiúscula
        }
    }

    return iniciais;
}

export function formatTime(value) {
    // Remove todos os caracteres não numéricos
    const onlyNums = value.replace(/[^\d]/g, '');

    // Formata no padrão 00:21
    if (onlyNums.length <= 2) {
        return onlyNums;
    }

    if (onlyNums.length <= 4) {
        return onlyNums.slice(0, 2) + ':' + onlyNums.slice(2);
    }
}

export function abreviarNome(fullName) {
    const parts = fullName.split(" ");
    if (parts.length > 2) {
        return `${parts[0]} ${parts.slice(1, -1).map(p => p[0] + '.').join(" ")} ${parts[parts.length - 1]}`;
    } else {
        return fullName;
    }
}