import { Link } from "react-router-dom";
import { useState } from "react";
import { FiAlignLeft, FiLogOut, FiSliders, FiUser, FiUsers, FiCalendar, FiDollarSign, FiBook, FiMenu, FiFileText, FiImage, FiFile, FiShare2, FiFilter, FiThermometer, FiCpu } from "react-icons/fi";

function Sidebar() {

    const closeSidebar = () => {
        setIsSidebarVisible(false);
    };
    const logoff = () => {
        window.location.href = "/login";
    }

    // Controla o sidedar
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    const toggleSidebar = () => {
        setIsSidebarVisible(!isSidebarVisible);
    };

    return (
        <>
            <Link className={`sidebar-toggle js-sidebar-toggle ${isSidebarVisible ? 'btn-sidebar-collapsed' : 'btn-sidebar'}`} onClick={toggleSidebar}>
                <FiAlignLeft className="align-self-center fs-1 text-hamburger" />
            </Link>
            <div className={`sidebar-placeholder ${isSidebarVisible ? 'sidebar-placeholder-collapsed' : ''}`}></div>
            <nav id="sidebar" className={`sidebar ${isSidebarVisible ? 'collapsed' : ''}`}>
                <div className="sidebar-content">
                    <Link className="sidebar-brand">
                        <span className="align-middle">Trackster</span>
                    </Link>
                    <ul className="sidebar-nav">
                        <li className="sidebar-header">
                            Painel de Controle
                        </li>

                        <li className="sidebar-item">
                            <Link className="sidebar-link" onClick={closeSidebar} to="/dashboard">
                                <FiSliders /> <span className="align-middle">Dashboard</span>
                            </Link>
                        </li>

                        <li className="sidebar-header">
                            Data
                        </li>



                        <li className="sidebar-item">
                            <Link className="sidebar-link" onClick={closeSidebar} to="/filtrar-dados">
                                <FiFilter /> <span className="align-middle">Filter</span>
                            </Link>
                        </li>

                        <li className="sidebar-header">
                            Em breve
                        </li>

                        <li className="sidebar-item">
                            <Link className="sidebar-link" onClick={closeSidebar} to="/dashboard">
                                <FiThermometer /> <span className="align-middle">Heatmaps</span>
                            </Link>
                        </li>
                        <li className="sidebar-item">
                            <Link className="sidebar-link" onClick={closeSidebar} to="/dashboard">
                                <FiCpu /> <span className="align-middle">AI</span>
                            </Link>
                        </li>



                        <li className="sidebar-header">
                            Outros
                        </li>

                        <li className="sidebar-item">
                            <Link className="sidebar-link" onClick={closeSidebar} to="/perfil">
                                <FiUser /> <span className="align-middle">Meu Perfil</span>
                            </Link>
                        </li>


                    </ul>


                    <Link className="sidebar-link" onClick={logoff} to="/logoff">
                        <FiLogOut /> <span className="align-middle">Logoff</span>
                    </Link>

                </div>
            </nav>
        </>
    )
}

export default Sidebar;
