import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
                <p>{`${String(label).padStart(2, '0')}:00`}</p>                <p>{`Vitórias: ${payload[0].value.toFixed(2)}%`}</p>
                <p>{`Derrotas: ${payload[1].value.toFixed(2)}%`}</p>
                <p>{`Total de Partidas: ${payload[0].payload.totalMatches}`}</p>
            </div>
        );
    }
    return null;
};

const HourlyWinsLossesChart = ({ data }) => {
    if (!data || data.length === 0) {
        return <p>No data available</p>;
    }

    const chartData = data.map(item => ({
        ...item,
        winPercent: (item.wins / item.totalMatches) * 100,
        lossPercent: (item.losses / item.totalMatches) * 100,
    }));

    return (
        <ResponsiveContainer width="100%" height={300}>
            <AreaChart data={chartData} stackOffset="expand">
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="hour" />
                <YAxis tickFormatter={tick => `${(tick*100)}%`} />
                <Tooltip content={<CustomTooltip />} />
                <Legend />
                <Area type="monotone" dataKey="winPercent" stackId="1" stroke="#82ca9d" fill="#82ca9d" name="Vitórias (%)" />
                <Area type="monotone" dataKey="lossPercent" stackId="1" stroke="#b16990" fill="#b16990" name="Derrotas (%)" />
            </AreaChart>
        </ResponsiveContainer>
    );
}

export default HourlyWinsLossesChart;
